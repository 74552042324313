<template>
    <div class="list">
        <van-cell-group title="考试列表">
            <van-cell is-link v-for="(item, index) in list" :key="index" @click="toDteails(index)">
                <div>【{{ item.highest_grade }}分】 {{ item.exam_name }}</div>
            </van-cell>
        </van-cell-group>
        <!-- <div v-if="list.length == 0">暂无考试安排!</div> -->
    </div>
</template>

<script>
    import request from "../tools/request.js";
    import qs from "qs";
    import {
        Toast
    } from "vant";
    export default {
        name: "ExamList",
        props: {
            msg: String,
        },
        data() {
            return {
                list: [],
            };
        },
        methods: {
            toDteails(index) {
                localStorage.setItem('examDetails', JSON.stringify(this.list[index]))
                this.$router.push({
                    name: "ExamDetails",
                    params: this.list[index],
                });
            },
        },
        mounted() {
            
            localStorage.setItem("name", this.$route.params.name);
            localStorage.setItem("phone", this.$route.params.phone);
            localStorage.setItem("email", this.$route.params.email);
            localStorage.setItem("user_id", this.$route.params.user_id);
            var userid = localStorage.getItem("user_id");
            let that = this;
            let ts = Date.parse(new Date());
            var parms = {
                exam_time: Date.parse(new Date()) / 1000,
                classify_name: "EZSVS_BOX",
                student_id: userid,
            };
            request
                .get("getStudentExamList", {
                    params: parms,
                })
                .then((res) => {
                    if (res.status_code === 200) {
                        that.list = res.data.list;
                    } else {
                        Toast.fail(res.message);
                    }
                });
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .list {
        margin-top: 0px;
    }
</style>
