<!--
 * @Descripttion: 
 * @version: 
 * @Author: zhangyuehai
 * @Date: 2022-02-09 10:24:22
 * @LastEditors: zhangyuehai
 * @LastEditTime: 2022-03-07 15:40:46
-->
<template>
  <div class="app-container">
    <div class="filter-container">
      <el-date-picker
        v-model="time"
        prop="time"
        type="daterange"
        end-placeholder="结束日期"
        size="small"
        align="right"
        unlink-panels
        value-format="yyyy-MM-dd"
        range-separator="至"
        start-placeholder="开始日期"
        @change="handleFilter"
      />
      <el-input
        v-model="listQuery.keyword"
        size="small"
        placeholder="人员、部门关键词"
        style="width: 200px; margin-left: 10px"
        class=""
        @keyup.enter.native="handleFilter"
      />
      <el-button
        size="small"
        style="margin-left: 10px"
        class=""
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
      >
        搜索
      </el-button>
      <el-button
        size="small"
        style="margin-left: 10px"
        class=""
        type="primary"
        icon="el-icon-download"
        @click="exportEexcel"
      >
        导出
      </el-button>
    </div>
    <el-table
      :row-style="{ height: '20px' }"
      :cell-style="{ padding: '0px' }"
      ref="dragTable"
      v-loading="listLoading"
      :data="list"
      row-key="id"
      border
      fit
      highlight-current-row
      style="width: 100%; font-size: 10px"
    >
      <el-table-column align="center" label="序号" width="55">
        <template slot-scope="scope">
          <span>{{ scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column width="" align="center" label="姓名" width="100px">
        <template slot-scope="{ row }">
          <span>{{ row.user_name }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="现任部门" width="150px">
        <template slot-scope="{ row }">
          <span>{{ row.department }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="邮箱" width="250px">
        <template slot-scope="{ row }">
          <span>{{ row.user_email }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="电话" width="150px">
        <template slot-scope="{ row }">
          <span>{{ row.user_phone }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="技能"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="{ row }">
          <span v-for="item in row.many_label_list" :key="item.user_id"
            >{{ item.show_label }}
          </span>
        </template>
      </el-table-column>
    </el-table>
    <v-pagination
      v-show="listQuery.total > 0"
      :total="listQuery.total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.pageSize"
      @pagination="getList"
    />
  </div>
</template>

<script>
import request from "@/tools/request.js";
import {publicDownLoad} from "@/utils/index.js"
export default {
  name: "PersonnelReport",
  data() {
    return {
      time: ["", ""],
      list: [],
      listLoading: true,
      listQuery: {
        page: 1,
        pageSize: 10,
        total: 100,
        keyword: "",
        start_time: this.time ? this.time[0] : "",
        end_time: this.time ? this.time[1] : "",
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      const that = this;
      this.listLoading = true;
      this.listQuery.start_time = this.time ? this.time[0] : "";
      this.listQuery.end_time = this.time ? this.time[1] : "";
      request
        .get("getBoxUserLabel", {
          params: this.listQuery,
        })
        .then((response) => {
          that.listLoading = false;
          if (response.status_code === 200) {
            // console.log(JSON.parse(JSON.stringify(response.data.list)));
            that.list = JSON.parse(JSON.stringify(response.data.list));
            that.listQuery.total = response.data.total
              ? Number(response.data.total)
              : 0;
            that.listQuery.limit = Number(response.data.limit);
            that.listQuery.page = Number(response.data.page);
            // eslint-disable-next-line no-empty
          } else {
          }
        })
        .catch((errors) => {
          that.submitLoading = false;
        });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    closeModal() {
      this.showAddModal = false;
      this.showSubjectModal = false;
    },
    reloadList: function (item) {
      this.getList();
    },
    exportEexcel() {
      this.listQuery.start_time = this.time ? this.time[0] : "";
      this.listQuery.end_time = this.time ? this.time[1] : "";
      publicDownLoad(  process.env.VUE_APP_BASE_API+"downBoxUserLabel"+'?end_time='+ this.listQuery.end_time+'&start_time='+this.listQuery.start_time+'&keyword='+this.listQuery.keyword)
    },
  },
};
</script>

<style>
.sortable-ghost {
  opacity: 0.8;
  color: #fff !important;
  background: #42b983 !important;
}
</style>

<style scoped>
/deep/ .el-table th.el-table__cell > .cell {
  white-space: nowrap;
  　　text-overflow: ellipsis;
  　　overflow: hidden;
}
.icon-star {
  margin-right: 2px;
}

.drag-handler {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.show-d {
  margin-top: 15px;
}
</style>
