<template>
 

<div class="details">
    

<!-- <van-button type="primary" block>知道了(去做答)</van-button> -->
<van-button class="botton1" color="#74b6be" block type="primary" size="large" @click="toAnswer">我知道了（开始作答）</van-button>

<div class="black">您好，欢迎参加本次考试</div>
<div class="black1">注意事项</div>
<van-divider />
<div class="black">1.系统自动计时，到时自动交卷;</div>
<div class="black">2.如果异常中断，请退出并及时按同样步骤进入，继续答题;</div>
<div class="red">3.答题时若按返回键返回（或home键最小化窗口），此时系统继续计时，直到交卷;</div>

  <div class="black1">试卷明细</div>
  <van-divider />
  <div class="black">1.试卷名称: {{examInfo.test_paper_name}}</div>
<div class="black">2.题型: 单选题({{examInfo.one_choice_topic_count}}道)、多选题({{examInfo.more_choice_topic_count}}道)、判断题({{examInfo.judge_topic_count}}道)</div>
<div  class="black">3.题目数: {{parseInt(examInfo.more_choice_topic_count)+parseInt(examInfo.one_choice_topic_count)+parseInt(examInfo.judge_topic_count )}}道</div>
<div class="black" >4.总分: {{examInfo.grade}}分</div>
<div class="black" >5.及格分: {{examInfo.passing_grade}}分</div>
<div  class="black">6.答题时长: {{examInfo.duration}}分钟</div>
<div  class="black">7.考试说明: {{examInfo.describe}}</div>






</div>


</template>

<script>
export default {
  name: 'ExamDetails',
  props: {
    msg: String,
    
  },
  data() {
    return {
      list:['六级考试','四级考试','八级考试'],
      examInfo:{
         

      }
    }
  },
  methods: {
      toAnswer() {
        let rowData = localStorage.getItem('examDetails');
        this.examInfo = JSON.parse(rowData);
          this.$router.replace({ 
            name: 'Examination', 
            // params: this.examInfo

          });
      }
  },
  // beforeDestroy(){
  //       console.log('hahahahah哈哈000');
        

  //       localStorage.removeItem('examDetails')
  //   }, 
  
created() {

  
    console.log('创建');
    let rowData = localStorage.getItem('examDetails')
     this.examInfo = JSON.parse(rowData);
    // if(rowData) {
      
    //   this.examInfo = JSON.parse(rowData);
    // }else{
    //   this.examInfo = this.$route.params;
    //   localStorage.setItem('examDetails', JSON.stringify(this.$route.params))
    // }

  },
  
     



}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.details{
    margin: 20px 50px;
    /* max-width: 500px;
    max-height: 1000px; */
    
}

.red{
    color: red;
    padding: 10rpx;
    padding-left: 30rpx;
}
.black{
    color: black;
    padding: 10rpx;
    padding-left: 30rpx;
    /* background-color: blue; */
}
.black1{
    color: black;
    padding: 10rpx;
    margin-bottom: -25rpx;
    padding-left: 30rpx;

    /* background-color: green; */
    

}
.botton1{
    flex: 1;
}

</style>
