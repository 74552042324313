<template>
    <div class="examcontent">
        <div class="zhengweng">
            <div v-for="(oneitem, oneindex) in many_topics" :key="oneitem._id">
                <div class="h5">
                    {{ oneindex + 1 }}、【{{ types[oneitem.question_type] }}、{{ oneitem.grade }}分】
                </div>
                <div class="h4 exam-question-name">
                    {{ oneitem.question_name }}
                </div>
                <van-radio-group v-model="oneitem.student_checked_option[0]" v-if="
                                            oneitem.question_type === '1' ||
                                            oneitem.question_type === '3'
                                        " @change="changesss(oneitem)">
                    <div class="subject" v-for="(twoitem, twoindex) in oneitem.option" :key="twoindex">
                        <van-radio v-bind:name="twoitem.value" class="h4">
                            {{ String.fromCharCode(65 + twoindex) }}、{{ twoitem.lable }}
                        </van-radio>
                    </div>
                </van-radio-group>
                <van-checkbox-group v-model="oneitem.student_checked_option" v-else-if="oneitem.question_type === '2'" @change="changesss(oneitem)">
                    <div class="subject" v-for="(twoitem, twoindex) in oneitem.option" :key="twoindex">
                        <van-checkbox v-bind:name="twoitem.value" class="h4">
                            {{ String.fromCharCode(65 + twoindex) }}、{{ twoitem.lable }}
                        </van-checkbox>
                    </div>
                </van-checkbox-group>
            </div>
        </div>
        <!-- <div v-if="many_topics.length>0" class="jiaojuan" @click="handInPaper">
            <div class="jiaojuan1">
                <van-count-down :time="time" @finish="finished" />
                <span>
                          交卷
                        </span>
            </div>
        </div> -->
        <van-tabbar v-model="active" :border="false">
            <div class="jiaojuan1" @click="handInPaper" >
                <van-count-down :time="time" @finish="finished" class="countdown" />
                <span>
                          交卷
                        </span>
            </div>
        </van-tabbar>
    </div>
</template>

<script>
    import request from "../tools/request.js";
    import {
        Dialog,
        Toast
    } from "vant";
    import qs from "qs";
    export default {
        name: "Examination",
        data() {
            return {
                bottom: "0px",
                radio: "2",
                many_topics: [],
                time: 1 * 60 * 60 * 1000,
                types: {
                    1: "单选题",
                    2: "多选题",
                    3: "判断题",
                },
                student_id: "",
                exam_id: "",
                student_exam_id: "",
                exam_name: "",
                examInfo: {},
            };
        },
        methods: {
            choice(item) {},
            finished() {
                let that = this;
                var userid = localStorage.getItem("user_id");
                request
                    .post(
                        "handExam",
                        qs.stringify({
                            student_exam_id: that.student_exam_id,
                            student_id: userid,
                        })
                    )
                    .then((res) => {
                        if (res.status_code === 200) {
                            let rowData = localStorage.getItem('examDetails')
                            var dt = JSON.parse(rowData);
                            dt.sudent_grade = res.data.list.sudent_grade;
                            dt.highest_grade = dt.highest_grade > dt.sudent_grade ? dt.highest_grade : dt.sudent_grade;
                            localStorage.setItem('examDetails', JSON.stringify(dt));
                            that.$router.replace({
                                name: "HandPaper",
                            });
                        } else {}
                        Dialog.alert({
                            message: "考试时间已到，系统已自动提交！",
                        }).then(() => {
                            // on close
                        });
                    });
            },
            changesss(oneitem) {
                console.log(oneitem);
                var user_id = localStorage.getItem('user_id');
                // https://yfbapi.yifucs.com/api/answerExam?student_exam_id=333&student_id=aolxkpp3fue3xhsck&exam_topic_id=4342&student_checked_option=1
                var formData = {
                    student_exam_id: oneitem.student_exam_id,
                    student_id: user_id,
                    exam_topic_id: oneitem._id,
                    student_checked_option: oneitem.student_checked_option,
                };
                console.log(qs.stringify(formData));
                request.post("answerExam", qs.stringify(formData)).then((res) => {
                    console.log(res);
                    console.log("ppppp");
                    if (res.status_code === 200) {} else {
                        Dialog.alert({
                            message: res.message,
                        }).then(() => {
                            // on close
                        });
                    }
                });
            },
            handInPaper() {
                let that = this;
                let answerNum = 0;
                for (const key in this.many_topics) {
                    if (Object.hasOwnProperty.call(this.many_topics, key)) {
                        const element = this.many_topics[key];
                        if (element.student_checked_option.length == 0) {
                            answerNum++;
                        }
                    }
                }
                let mesagesss = answerNum == 0 ? '您已答完全部题目，确定交卷?' : '您还有' + answerNum + '题未答，确定交卷?'
                var user_id = localStorage.getItem('user_id');
                Dialog.confirm({
                        title: "提示",
                        message: mesagesss,
                    })
                    .then(() => {
                        request
                            .post(
                                "handExam",
                                qs.stringify({
                                    student_exam_id: that.student_exam_id,
                                    student_id: user_id,
                                })
                            )
                            .then((res) => {
                                console.log('mkjkjkjk', res)
                                if (res.status_code === 200) {
                                    let rowData = localStorage.getItem('examDetails')
                                    var dt = JSON.parse(rowData);
                                    dt.sudent_grade = res.data.list.sudent_grade;
                                    dt.highest_grade = dt.highest_grade > dt.sudent_grade ? dt.highest_grade : dt.sudent_grade;
                                    localStorage.setItem('examDetails', JSON.stringify(dt));
                                    that.$router.replace({
                                        name: "HandPaper",
                                    });
                                } else {
                                    Toast.fail(res.message);
                                }
                            });
                    })
                    .catch(() => {});
            },
        },
        // beforeDestroy(){
        //         console.log('hahahahah哈哈000');
        //         localStorage.removeItem('examDetailss')
        //     }, 
        // created() {
        //     console.log('创建');
        //     let rowData = localStorage.getItem('examDetailss')
        //     if(rowData) {
        //        console.log('存在');
        //       this.examInfo = JSON.parse(rowData);
        //     }else{
        //          console.log('不存在');
        //       this.examInfo = this.$route.params;
        //       console.log(JSON.stringify(this.$route.params));
        //       localStorage.setItem('examDetailss', JSON.stringify(this.$route.params))
        //     }
        // console.log(this.$route.params);
        //   },
        mounted() {
            let rowData = localStorage.getItem('examDetails');
            console.log('挂载');
            let parm = JSON.parse(rowData);
            let that = this;
            let user_id = localStorage.getItem('user_id');
            let name = localStorage.getItem('name');
            let phone = localStorage.getItem('phone');
            let email = localStorage.getItem('email');
            let parms = {
                student_id: user_id,
                student_name: name,
                student_phone: phone,
                sudent_email: email,
                exam_id: parm._id
            }
            request
                .get(
                    "getStudentExamDetail", {
                        params: parms,
                    }
                )
                .then((res) => {
                    if (res.status_code === 200) {
                        var timestamp = Date.parse(new Date());
                        var end_time11 = parseInt(res.data.list.end_time) * 1000;
                        that.time = end_time11 - timestamp;
                        that.many_topics = res.data.list.many_topics;
                        that.student_exam_id = res.data.list._id;
                        localStorage.setItem('student_exam_id', that.student_exam_id);
                    } else {
                        Toast.fail(res.message);
                    }
                });
        },
    };
</script>

<style scoped>
   
    
   
  
    
    .examcontent {
        height: 100%;
        /* background-color: #1500ff; */
       
    }
    .subject {
        margin: 10px;
        padding: 10px;
        border: 1px solid #797979;
        color: #1500ff;
    }
    .option {
        padding: 10px;
    }
    .van-dropdown-menu {
        /* background-color:rgb(134, 78, 78) !important; */
        box-shadow: 0 0px 0px rgba(21, 153, 87, 0.12) !important;
        height: 80px !important;
    }
    .numbers {
        /* border-top: 1px solid #797979; */
        display: flex;
        /* background-color: bisque; */
        flex-wrap: wrap;
        min-height: 300px;
        max-height: 900px;
        align-content: flex-start;
        justify-content: flex-start;
        overflow: auto;
        /* justify-content:center; */
    }
    .number {
        /* background-color: yellowgreen; */
        width: 20%;
        height: 100px;
        /* border: 1px solid; */
        display: flex;
        align-content: center;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        margin: 20px 0px;
    }
    /* .number::before{content: "";padding-top: 50%;display: block;} */
    .number1 {
        /* background-color: aqua; */
        width: 100px;
        height: 100px;
        border: 1px solid #797979;
        border-radius: 5px 5px 0 5px;
        /* position: absolute; */
        line-height: 100px;
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap-reverse;
    }
    .triangle-left {
        /* position:absolute; */
        width: 0;
        height: 0;
        border-bottom: 20px solid #199ed8;
        border-left: 20px solid transparent;
        /* background-color: #1500ff; */
    }
    .botton {
        background-color: #ffff !important;
    }
    
    .botton2 {
        flex: 1;
        text-align: center;
        padding: 20px;
        border: 1px solid #797979;
        /* height: 80px; */
        border-left: 0.5px solid #797979;
        border-right: 0px solid #797979;
        background-color: white;
    }
    .botton3 {
        flex: 1;
        text-align: center;
        padding: 20px;
        border: 1px solid #797979;
        /* height: 80px; */
        border-left: 0.5px solid #797979;
        border-right: 0px solid #797979;
        background-color: #1500ff;
        color: #fff;
    }
    .done {
        /* background-color: rebeccapurple; */
        font-size: 18px;
        margin-top: -20px;
        margin-left: -20px;
        color: white;
    }
    .number1no {
        width: 100px;
        height: 100px;
        border: 1px solid #797979;
        border-radius: 5px 5px 5px 5px;
        /* position: absolute; */
        line-height: 100px;
        display: flex;
        /* flex-direction: row-reverse; */
        /* flex-wrap: wrap-reverse; */
        align-content: center;
        justify-content: center;
    }
    .numbernodone {
        background-color: red;
    }
    .h4 {
        word-break: break-all;
        font-size: 14px;
    }
    .h5 {
        word-break: break-all;
        font-size: 14px;
    }
    .nextPrevious {
        background-color: royalblue;
        display: flex;
        height: 40px;
    }
    .previousBT {
        flex: 1;
        background-color: royalblue;
    }
    .nextBT {
        flex: 1;
        background-color: royalblue;
    }
    .jiaojuan {
        text-align: center;
        background-color: white;
        width: 100%;
        position: fixed;
        bottom: 0;
    }
    .jiaojuan1 {
        background-color: rebeccapurple;
        margin: 5px 20px;
        padding: 10px;
        border-radius: 5px;
        color: white;
        display: flex;
        justify-content: center;
        font-size: 15px;
        width: 100%;
        height: 30px;
        line-height: 30px;
    }
    .zhengweng {
        margin: 10px 10px 0px 10px ;
        padding-bottom: 80px;
       
      
       
        /* background-color: #1500ff; */

        /* overflow: auto; */
    }
    .countdown {
        /* background-color: #1500ff; */
        /* text-align: center;
        padding: 5px; */
        line-height: 30px !important;
    }
    .exam-question-name {
        margin: 5px;
    }
</style>
