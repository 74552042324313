<template>
    <!-- <div class="bodys">
            
          
            <div class="jifencontent">
                <van-nav-bar title="积分中心" left-text=" " left-arrow @click-left="onClickLeft" safe-area-inset-top />
                <div class="my-integral">
                    <div class="my-integra1">
                        我的积分
                    </div>
                    <div class="my-integra2">
                        积分说明 >
                    </div>
                    <div class="my-integra3">
                        6280
                    </div>
                </div>
                <van-tabs v-model:active="active" type="card" title-active-color="#fff" title-inactive-color="#BDBDBD" color="green">
                    <van-tab title="个人积分榜">
                        <div class="topThree">
                            <div class="topItem topItem1">
                                <div class="top-item-index">
                                    <div class="top-item-index-one">
                                        2
                                    </div>
                                </div>
                                <div class="top-item-icon">
                                    小丽
                                </div>
                                <div class="top-item-name">
                                    王晓丽
                                </div>
                                <div class="top-item-number">
                                    234
                                </div>
                            </div>
                            <div class="topItem topItem2">
                                <div class="top-item-index">
                                    <div class="top-item-index-two">
                                        1
                                    </div>
                                </div>
                                <div class="top-item-icon">
                                    小丽
                                </div>
                                <div class="top-item-name">
                                    王晓丽
                                </div>
                                <div class="top-item-number">
                                    400
                                </div>
                            </div>
                            <div class="topItem topItem3">
                                <div class="top-item-index">
                                    <div class="top-item-index-three">
                                        3
                                    </div>
                                </div>
                                <div class="top-item-icon">
                                    小张
                                </div>
                                <div class="top-item-name">
                                    王晓丽
                                </div>
                                <div class="top-item-number">
                                    500
                                </div>
                            </div>
                        </div>
                        <div class="lists">
                            <van-cell v-for="(item,index) in list" :key="index ">
                                <div class="item-list">
                                    <div class="item-list-index">
                                        {{index+1}}
                                    </div>
                                    <div class="item-list-icon">
                                        小马
                                    </div>
                                    <div class="item-list-name">
                                        {{item.name}}
                                    </div>
                                    <div class="item-list-number">
                                        1000
                                    </div>
                                </div>
                            </van-cell>
                        </div>
                    </van-tab>
                    <van-tab title="团队积分榜">
                        <div class="topThree">
                            <div class="topItem topItem1">11</div>
                            <div class="topItem topItem2">22</div>
                            <div class="topItem topItem3">33</div>
                        </div>
                        <van-cell v-for="(item,index) in groups" :key="index ">
                            <div>
                                {{item.name}}
                            </div>
                        </van-cell>
                    </van-tab>
                </van-tabs>
            </div>
        </div> -->
    <div class="body-one">
        <div class="nav-title">
            <van-nav-bar title="积分中心" left-text=" " left-arrow @click-left="onClickLeft" safe-area-inset-top />
        </div>
        <div class="integrate-count-one">
            <div class="my-integral">
                <div class="my-integra1">
                    我的积分
                </div>
                <div class="my-integra2" @click="checkPoints">
                    积分说明 >
                </div>
                <div class="my-integra3">
                    6280
                </div>
            </div>
        </div>
        <div class="integrate-count-two">
            <van-tabs v-model:active="active" type="card" title-active-color="#fff" title-inactive-color="#BDBDBD" color="#ec5c57">
                <van-tab title="个人积分榜">
                    <div class="topThree">
                        <div class="topItem topItem1" v-if="list[1]">
                            <div class="top-item-index">
                                <div class="top-item-index-one">
                                    2
                                </div>
                            </div>
                            <div class="top-item-icon">
                                {{ list[1].name.substring(list[1].name.length - 2)}}
                            </div>
                            <div class="top-item-name">
                                {{list[1].name}}
                            </div>
                            <div class="top-item-number">
                                {{list[1].integral}}
                            </div>
                        </div>
                        <div class="topItem topItem2" v-if="list[0]">
                            <div class="top-item-index">
                                <div class="top-item-index-two">
                                    1 
                                </div>
                            </div>
                            <div class="top-item-icon">
                                 {{ list[0].name.substring(list[0].name.length - 2)}}
                            </div>
                            <div class="top-item-name">
                                {{list[0].name}}
                            </div>
                            <div class="top-item-number">
                                {{list[0].integral}}
                            </div>
                        </div>
                        <div class="topItem topItem3" >
                            <div v-if="list[1]">
                                <div class="top-item-index">
                                <div class="top-item-index-three">
                                    3
                                </div>
                            </div>
                             <div class="top-item-icon">
                                 {{ list[2].name.substring(list[2].name.length - 2)}}
                            </div>
                            <div class="top-item-name">
                                {{list[2].name}}
                            </div>
                            <div class="top-item-number">
                                {{list[2].integral}}
                            </div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="lists">
                        <van-cell v-for="(item,index) in list" :key="index ">
                            <div class="item-list">
                                <div class="item-list-index">
                                    {{index+1}}
                                </div>
                                <div class="item-list-icon">
                                    {{item.name.substring(item.name.length - 2)}}
                                </div>
                                <div class="item-list-name">
                                    {{item.name}}
                                </div>
                                <div class="item-list-number">
                                    {{item.integral}}
                                </div>
                            </div>
                        </van-cell>
                    </div>
                </van-tab>
                <van-tab title="团队积分榜">
                    <div class="topThree">
                        <div class="topItem topItem1" v-if="groups[1]">
                            <div class="top-item-index">
                                <div class="top-item-index-one">
                                    2
                                </div>
                            </div>
                            <div class="top-item-icon">
                                {{ groups[1].one_department.name.substring(groups[1].one_department.name.length - 2)}}
                            </div>
                            <div class="top-item-name">
                                {{groups[1].one_department.name}}
                            </div>
                            <div class="top-item-number">
                                {{groups[1].integral}}
                            </div>
                        </div>
                        <div class="topItem topItem2" v-if="list[0]">
                            <div class="top-item-index">
                                <div class="top-item-index-two">
                                    1 
                                </div>
                            </div>
                            <div class="top-item-icon">
                                 {{ groups[0].one_department.name.substring(groups[0].one_department.name.length - 2)}}
                            </div>
                            <div class="top-item-name">
                                {{groups[0].one_department.name}}
                            </div>
                            <div class="top-item-number">
                                {{groups[0].integral}}
                            </div>
                        </div>
                        <div class="topItem topItem3" >
                            <div v-if="groups[1]">
                                <div class="top-item-index">
                                <div class="top-item-index-three">
                                    3
                                </div>
                            </div>
                             <div class="top-item-icon">
                                 {{ groups[2].one_department.name.substring(groups[2].one_department.name.length - 2)}}
                            </div>
                            <div class="top-item-name">
                                {{groups[2].one_department.name}}
                            </div>
                            <div class="top-item-number">
                                {{groups[2].integral}}
                            </div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="lists">
                        <van-cell v-for="(item,index) in groups" :key="index ">
                            <div class="item-list">
                                <div class="item-list-index">
                                    {{index+1}}
                                </div>
                                <div class="item-list-icon">
                                    {{item.one_department.name.substring(item.one_department.name.length - 2)}}
                                </div>
                                <div class="item-list-name">
                                    {{item.one_department.name}}
                                </div>
                                <div class="item-list-number">
                                    {{item.integral}}
                                </div>
                            </div>
                        </van-cell>
                    </div>
                </van-tab>
            </van-tabs>
        </div>
        <div class="integrate-footer">
            - 排名最多展示前50名，如有疑问，联系人力资源部 -
        </div>
    </div>
</template>

<script>
    import request from "../../tools/request.js";
    export default {
        name: 'IntegrateList',
        data() {
            return {
                active: 0,
                list: [],
                groups: []
            }
        },
        methods: {
            onClickLeft() {},
            checkPoints() {
                this.$router.push({
                    name: "ExamExplain",
                });
            }
        },
        mounted() {
            let that = this;
            var parms = {
                user_id: '9142305782080817903'
            };
            request
                .get("scoreboardList", {
                    params: parms,
                })
                .then((res) => {
                    console.log(res);
                    if (res.status_code === 200) {
                        that.list = res.data.personage_list;
                        that.groups = res.data.team_list;
                    } else {
                        Toast.fail(res.message);
                    }
                });
        }
    }
</script>

<style scoped>
    .body-one {
        width: 100%;
        height: 100%;
        background-color: #ec5c57;
        display: flex;
        flex-direction: column;
    }
    .nav-title {
        height: 40px;
    }
    .integrate-count-one {
        height: 70px;
        /* background-color: green; */
        margin: 15px 15px 0px 15px;
    }
    .integrate-count-two {
        flex: 1;
        background-color: white;
        margin: 15px 15px 0px 15px;
        padding: 15px 0px 0px 0px;
        overflow: auto;
        border-radius: 8px;
    }
    .integrate-footer {
        height: 30px;
        font-size: 14px;
        text-align: center;
        color: white;
    }
    .bodys {
        height: 100%;
        background: #ec5c57;
        /* background:url('../../assets/qidongtu.png')  4px 5px; */
    }
    .loginbackImg {
        width: 100%;
        /* height: 100%; */
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        position: absolute;
        z-index: -1;
    }
    .jifencontent {
        z-index: 10;
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .van-hairline--bottom:after {
        border-bottom-width: 0px;
    }
    .my-integral {
        background-color: #fff;
        height: 70px;
        /* margin: 15px; */
        border-radius: 6px;
    }
    .my-integra1 {
        float: left;
        /* background-color: orchid; */
        padding: 8px 0px 5px 15px;
    }
    .my-integra2 {
        /* background-color: red; */
        text-align: right;
        padding: 8px 15px 5px 0px;
    }
    .my-integra3 {
        color: red;
        text-align: left;
        padding: 3px 15px;
        font-size: 20px;
    }
    .top-item-index {
        /* background-color: green; */
        height: 30px;
        margin-top: -10px;
        text-align: center;
    }
    .top-item-index-one {
        width: 20px;
        height: 20px;
        background-color: #c1c1c1;
        margin: 0px auto;
        border-radius: 3px;
    }
    .top-item-index-two {
        width: 20px;
        height: 20px;
        background-color: #e5c079;
        margin: 0px auto;
        border-radius: 3px;
    }
    .top-item-index-three {
        width: 20px;
        height: 20px;
        background-color: #DD9E79;
        margin: 0px auto;
        border-radius: 3px;
    }
    .top-item-icon {
        background-color: blue;
        height: 40px;
        width: 40px;
        border-radius: 20px;
        margin: 0px auto;
        text-align: center;
        line-height: 40px;
        color: #fff;
    }
    .top-item-name {
        /* background-color: aquamarine; */
        height: 25px;
        text-align: center;
        line-height: 25px;
        padding-top: 5px;
        width: 100px;
        overflow: hidden;
        margin: 0 auto;
        
    }
    .top-item-number {
        color: red;
        height: 30px;
        text-align: center;
        line-height: 30px;
        font-size: 25px;
        margin-top: 5px;
        width: 100px;
        overflow: hidden;
         margin: 0 auto;
    }
    .topThree {
        margin-top: 10px;
        /* background-color: yellow; */
        display: flex;
    }
    .topItem {
        border-radius: 5px;
        height: 135px;
        flex: 1;
        margin: 5px 10px;
        background-color: orange;
        /* text-align: center;
                                line-height: 150px; */
        box-shadow: 0 0px 2px 2px rgb(226, 221, 221);
        transition: all .25s linear;
        text-align: center;
    }
    .topItem1 {
        margin-top: 30px;
    }
    .topItem2 {
        margin-top: 10px;
    }
    .topItem3 {
        margin-top: 30px;
    }
    .item-list {
        display: flex;
        height: 50px;
        /* background-color: aquamarine; */
    }
    .item-list-index {
        width: 25px;
        /* background-color: rebeccapurple; */
        text-align: center;
        line-height: 50px;
        font-size: 20px;
        padding: 0px 5px;
        color: #A2A2A2;
    }
    .item-list-icon {
        width: 40px;
        height: 40px;
        background-color: blue;
        text-align: center;
        line-height: 40px;
        border-radius: 20px;
        color: #fff;
        margin-top: 5px;
    }
    .item-list-name {
        flex: 1;
        /* background-color: rgb(111, 0, 255); */
        text-align: left;
        line-height: 50px;
        color: black;
        font-size: 16px;
        padding-left: 10px;
        height: 50px;
        
    }
    .item-list-number {
        width: 70px;
        /* background-color: orangered; */
        text-align: right;
        line-height: 50px;
        color: red;
        font-size: 25px;
    }
    .lists {
        /* background: rgb(185, 18, 18); */
        margin: 0px 0px;
        border-radius: 5px;
        padding: 0px;
    }
</style>