<template>
  <div class="test">
    <div class="wwww">答题已结束，感谢您的参与</div>
    <div class="name">{{name}}</div>
    <div class="div222">
      <div class="fenshu">{{grades.sudent_grade}}分{{grades.sudent_grade>grades.passing_grade?'及格':'不及格'}}</div>
      <div class="zongfen">（总分{{grades.grade}}/及格分{{grades.passing_grade}}）</div>
    </div>
    <div class="div222">
      <van-button class="div333" type="default" @click="toAnswer">重新作答</van-button>
      <van-button class="div333" type="default" @click="chakanduicuo">查看对错</van-button>
    </div>
    <div class="name">（最近间隔,最高成绩{{grades.highest_grade}}分）</div>
  </div>
</template>

<script>
  export default {
    name: 'HandPaper',
    data() {
      return {
        grades: {},
        name: ''
      };
    },
    methods: {
      toAnswer() {
        this.$router.replace({
          name: 'Examination',
          // params: this.examInfo
        });
      },
      chakanduicuo() {
        this.$router.replace({
          name: 'ViewResults',
          // params: this.examInfo
        });
      }
    },
    mounted() {
      // history.pushState(null, null, document.URL);
      // window.addEventListener('popstate', function() {
      //   history.pushState(null, null, document.URL);
      // });
      this.name = localStorage.getItem('name');
      let rowData = localStorage.getItem('examDetails');
      this.grades = JSON.parse(rowData);

    }
  }
</script>

<style scoped>
  .wwww {
    background: #012ffd;
    font-size: 18px;
    color: #fff;
    padding: 15px;
  }
  .name {
    font-size: 18px;
    padding-top: 20px;
    color: #000;
    text-align: center;
    padding: 15px;
  }
  .fenshu {
    font-size: 20px !important;
    padding-top: 20px;
    color: #000;
    text-align: right;
    /* padding: 15px; */
  }
  .zongfen {
    font-size: 20px;
    padding-top: 20px;
    color: #000;
    text-align: center;
  }
  .div222 {
    justify-content: center;
    /* margin-left: 10%;
    margin-right: 10%; */
    display: flex;
    flex-direction: row;
    padding: 10px;
    /* background-color: rebeccapurple; */
  }
  .div333 {
    flex: 1;
    text-align: center;
  }
</style>