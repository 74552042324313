

<template>
  <div class="bodys">
<van-tabbar v-model="active"   :border="true">
  <div class="bottons">
      底部
  </div>
</van-tabbar>

  </div>


</template>

<script>
export default {
    name:'AnQquan'

}
</script>

<style scoped>


.bodys{
    background-color: red;
    height: 100%;

}
.bottons{
    background-color: green;
    margin: 0 20px;
    width: 100%;
    text-align: center;
    padding: 10px;
}
</style>

