import Vue from 'vue'
import App from './App.vue'
import Moment from 'moment'
import router from 'vue-router'
import myruter from './index'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import components from '@/global_components/' // 加载公共组件

import 'vant/lib/index.less';

import {Tabbar, Row,Col,Button, Cell, icon, picker, CellGroup, Tab, Tabs, Toast, PullRefresh, NavBar, DatetimePicker, Popup, Calendar, Divider, GoodsAction, RadioGroup, Radio, DropdownMenu, DropdownItem, CountDown,Checkbox ,CheckboxGroup,Dialog} from 'vant';
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(router)
Vue.use(Button)
Vue.use(Cell)
Vue.use(icon)
Vue.use(picker)
Vue.use(CellGroup)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(CellGroup)
Vue.use(Button)
Vue.use(Toast)
Vue.use(PullRefresh)
Vue.use(NavBar);
Vue.use(DatetimePicker);
Vue.use(Popup);
Vue.use(Calendar)
Vue.use(Divider);
Vue.use(GoodsAction);
Vue.use(RadioGroup);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Radio);
Vue.use(CountDown);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Dialog);
Vue.use(Col);
Vue.use(Row);
Vue.use(Tabbar);



Object.keys(components).forEach((key) => {
    var name = key.replace(/(\w)/, (v) => v.toUpperCase()) // 首字母大写
    Vue.component(`v${name}`, components[key])
  })
  
new Vue({
    render: h => h(App),
    router: myruter
}).$mount('#app')