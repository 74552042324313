/*
 * @Descripttion: 
 * @version: 
 * @Author: zhangyuehai
 * @Date: 2022-02-28 10:38:11
 * @LastEditors: zhangyuehai
 * @LastEditTime: 2022-02-28 10:47:10
 */
    //下载
    export  function publicDownLoad(url) {
        if (url) {
            var result = url;
            window.opener = null
            window.open(result, '_self')
            var userAgent = navigator.userAgent
            if (userAgent.indexOf('Safari') > -1 || userAgent.indexOf('trident') > -1) {
                return 'Safari'
            } else if (userAgent.indexOf('Firefox') > -1) {
                return 'firefox'
            } else {
                setTimeout(function () {
                    window.close()
                }, 20)
            }
        } else {

        }
    }