/*
 * @Descripttion: 
 * @version: 
 * @Author: zhangyuehai
 * @Date: 2022-02-09 10:34:58
 * @LastEditors: zhangyuehai
 * @LastEditTime: 2022-02-09 10:37:10
 */

import Pagination from './Pagination/index.vue'

export default { Pagination }
