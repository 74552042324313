
import axios from 'axios'
import { Toast } from 'vant'
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base api url + request url
    withCredentials: false, // send cookies when cross-domain requests
    timeout: 20000,// request timeout
    headers: {
        'Content-Type':'application/x-www-form-urlencoded'
    }

})
service.interceptors.request.use(
    config => {
        // 不传递默认开启loading
        if (!config.hideloading) {
            // loading
            Toast.loading({
                forbidClick: true
            })
        }


        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)
service.interceptors.response.use(
    response => {
        Toast.clear()
        const res = response.data
        if (res.status && res.status !== 200) {
            // 登录超时,重新登录
            if (res.status === 401) {
                store.dispatch('FedLogOut').then(() => {
                    location.reload()
                })
            }
            return Promise.reject(res || 'error')
        } else {
            return Promise.resolve(res)
        }
    },
    error => {
        Toast.clear()
        console.log('err' + error) // for debug
        return Promise.reject(error)
    }
)

export default service