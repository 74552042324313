<template>
    


<router-view>



</router-view>


</template>

<script>
export default {
  data() {
    return {
      active: 0,
      count:0,
      isLoading:true

    };
  },
  methods: {
    
    
    
  },

  components:{
  
  
  

  },
  mounted(){
    
    console.log(this.$route.query);
    
  }
  
};
</script>

<style>

/* @media screen and (device-width:375px) and (device-height:812px){
    .footer {
      margin-bottom:34px;
    }
} */
/* 适配iphoneXR iphoneXSMax */
/* @media screen and (device-width:414px) and (device-height:896px){
    .footer {
      margin-bottom:34px;
    }
} */



#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  /* margin-top: 30px; */
}
html,body{
 height: 100%;
}
/* @supports (bottom: env(safe-area-inset-bottom)){
    body,
    .footer{
        padding-bottom: constant(safe-area-inset-bottom);
        padding-bottom: env(safe-area-inset-bottom);
    }
} */

</style>
