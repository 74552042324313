<template>
    <div class="zhengweng">
        <div v-for="(oneitem, oneindex) in many_topics" :key="oneitem._id">
            <div class="h5">
                {{ oneindex + 1 }}、【{{ types[oneitem.question_type] }}、{{ oneitem.grade }}分】
            </div>
            <div class="h4">
                {{ oneitem.question_name }}
            </div>
            <div class="subject" v-for="(twoitem, twoindex) in oneitem.option" :key="twoindex">
                <div class="h4">
                    {{ String.fromCharCode(65 + twoindex) }}、{{ twoitem.lable }}
                </div>
            </div>
            <div class="botton">
                <div class="botton1">
                    <div class="botton_title">
                        结果:
                    </div>
                    <div :class="['botton_content', { botton_content_status: oneitem.correct_option != oneitem.student_correct_option }]">
                        {{oneitem.correct_option == oneitem.student_correct_option ? '正确' : '错误'}}
                    </div>
                    <!-- <div class="botton_line">
                    </div> -->
                </div>
                <div class="botton1">
                    <div class="botton_title">
                        得分:
                    </div>
                    <div class="botton_content">
                        {{oneitem.student_grade}}
                    </div>
                    <!-- <div class="botton_line">
                    </div> -->
                </div>
                <div class="botton2">
                    <div class="botton_title">
                        考生答案:
                    </div>
                    <div class="botton_content">
                        {{realAnser(oneitem.student_checked_option)}}
                    </div>
                    <!-- <div class="botton_line">
                    </div> -->
                </div>
                <div class="botton2">
                    <div class="botton_title">
                        正确答案:
                    </div>
                    <div class="botton_content">
                        {{show_answe == '1'?'不可见':realAnser(oneitem.checked_option)}}
                    </div>
                </div>
            </div>
            <van-divider :style="{  borderColor: '#202020', padding: '0 16px' }">
            </van-divider>
        </div>
    </div>
    </div>
</template>

<script>
    import request from "../tools/request.js";
    export default {
        name: "ViewResults",
        data() {
            return {
                many_topics: [],
                show_answe:'',
                
                types: {
                    1: "单选题",
                    2: "多选题",
                    3: "判断题",
                },
                show_answe: "",
            };
        },
        methods: {
            realAnser(ansers) {
                var list = []
                if (!(ansers.length > 0)) {
                    return "";
                } else {
                    for (const key in ansers) {
                        if (Object.hasOwnProperty.call(ansers, key)) {
                            const element = ansers[key];
                            
                            list.push(String.fromCharCode(64 + parseInt(element)))
                        }
                    }
                    return list.join();
                }

            }
        },
        mounted() {
            let that = this;
            let student_exam_id = localStorage.getItem("student_exam_id");
            let user_id = localStorage.getItem("user_id");
            var parms = {
                student_exam_id: student_exam_id,
                student_id: user_id,
            };
            request
                .get("getStudentExamDetailLog", {
                    params: parms,
                })
                .then((res) => {
                    console.log(res);
                    that.many_topics = res.data.list.many_topics;
                    that.show_answe = res.data.list.show_answe;
                });
            console.log("哈哈哈哈", that.many_topics);
        },
    };
</script>

<style scoped>
    .subject {
        margin: 10px;
        /* padding: 10px 10px; */
        /* border: 1px solid #797979; */
        /* color: #1500ff; */
    }
    .botton {
        /* height: 30px; */
        display: flex;
        /* text-align: left; */
        /* font-size: 15px; */
    }
    .botton1 {
        display: flex;
        /* background-color: rgb(158, 78, 78) !important; */
        /* flex: 1.5; */
        margin: 2.5px 5px;
        align-items: center;
    }
    .botton2 {
        display: flex;
        /* background-color: rgb(158, 78, 78) !important; */
        /* flex: 2; */
        margin: 2.5px 5px;
        align-items: center;
    }
    .botton_content {
        /* flex: 1; */
        font-size: 14px;
        /* background-color: yellow; */
    }
    .botton_content_status{
        color: red;
        
    }
    .botton_title {
         font-size: 14px;
        /* flex: 2; */
        /* background-color: green; */
    }
    .botton_line {
        background-color: black;
        width: 1px;
        height: 40px;
    }
    .h4 {
        word-break: break-all;
         font-size: 14px;
    }
    .h5 {
        word-break: break-all;
         font-size: 14px;
    }
    .zhengweng {
        margin-bottom: 10px;
        margin: 20px;
    }
</style>