/*
 * @Descripttion: 
 * @version: 
 * @Author: zhangyuehai
 * @Date: 2022-02-25 11:08:57
 * @LastEditors: zhangyuehai
 * @LastEditTime: 2022-02-28 11:32:58
 */

import Vue from 'vue'
import Router from 'vue-router'
import ExamList from './components/ExamList'
import ExamDetails from './components/ExamDetails'
import Examination from './components/Examination'
import HandPaper from './components/HandPaper'
import ViewResults from './components/ViewResults'
import PersonnelReport from './views/box_ExaminationReport/PersonnelReport.vue'
import SkillReport from './views/box_ExaminationReport/SkillReport.vue'
import AnQquan from './components/AnQquan'

import IntegrateList from './components/integrate/IntegrateList'

import ExamExplain from './components/integrate/ExamExplain'

Vue.use(Router)
const router = new Router({
    routes: [{
            path: '/:user_id/:name/:phone/:email',
            name: 'ExamList',
            component: ExamList,
            meta: {
                title:'考试'
            }
        },
        {
            path: '/components/ExamDetails',
            name: 'ExamDetails',
            component: ExamDetails,
            

        },
        {
            path: '/components/Examination',
            name: 'Examination',
            component: Examination

        },
        {
            path: '/components/HandPaper',
            name: 'HandPaper',
            component:HandPaper

        },
        {
            path: '/components/ViewResults',
            name: 'ViewResults',
            component:ViewResults

        },
        {
            path: '/components/PersonnelReport',
            name: 'PersonnelReport',
            component:PersonnelReport

        },
        {
            path: '/components/SkillReport',
            name: 'SkillReport',
            component:SkillReport

        },
        // {
        //     path: '/',
        //     name: 'AnQquan',
        //     component:AnQquan

        // },
        // {
        //     path: '/IntegrateList',
        //     name: 'IntegrateList',
        //     component:IntegrateList

        // },
        // {
        //     path: '/ExamExplain',
        //     name: 'ExamExplain',
        //     component:ExamExplain

        // },
        


    ]
})

export default router