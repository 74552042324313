<template>
<div class="exolain">
    <!-- <div v-for="(item, index) in list" :key="index">
        {{item.name}}
    </div> -->
 <van-nav-bar title="积分说明" left-text=" " left-arrow @click-left="onClickLeft" safe-area-inset-top />


    <div  v-html="explain" class="explain-content">
        
    </div>
</div>
  
</template>

<script>
import request from "../../tools/request.js";
export default {
    name:'ExamExplain',
    data() {
        return {
             explain:'',
        }
    },
    methods: {
       onClickLeft(){
           this.$router.back();
       }
    },
    mounted() {
        console.log('我来了')
            let that = this;
           
            request
                .get("declareBody")
                .then((res) => {
                    console.log(res);
                    if (res.status_code === 200) {
                       that.explain = res.data.body;
                    } else {
                        Toast.fail(res.message);
                    }
                });
    },

}
</script>

<style scoped>
.exolain{
    background-color: #ec5c57;
}
.explain-content{
    margin: 20px;
    color: white;
}
.van-hairline--bottom:after {
        border-bottom-width: 0px !important;
    }
</style>